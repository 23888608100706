/**
 * Component: Region Selector
 */

/* --- namespace --- */
$ns: '.region-selector';

/* --- styles --- */
#{$ns} {
    .modal__box {
        min-width: 315px;
        padding: 86px 27px 89px 20px;

        @include at-tablet {
            padding: 57px 60px 47px 47px;
        }
    }

    &__list {
        display: none;

        @include at-tablet {
            display: block;
        }
    }

    &__item {
        display: flex;
        margin-bottom: 20px;
        color: $blueCharcoal;
        font-size: 16px;
        line-height: 16px;

        a {
            text-decoration: none;
        }
    }

    &__flag {
        margin-right: 19px;
    }

    &__select {
        @include at-tablet {
            display: none;
        }

        &-label {
            position: absolute;
            top: 30px;
            left: 20px;
        }
    }
}
