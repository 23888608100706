@mixin placeholder {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

// Hide scroll bars

@mixin hide-scroll-bar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }
}

/**
* For hover states
*
* Usage:
* .selector {
*   @include on-hover {
*     opacity: 90%
*   };
* }
*/

@mixin on-hover() {
    $hover: hover;

    @media (hover: $hover) {
        &:hover {
            @content;
        }
    }
}

// for use with image snippet, but can also be used by adding data-media
// attribute to media asset and applying mixin to its container

@mixin aspect-ratio-media($width, $height) {
    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    [data-media] {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        // font-family: 'object-fit: cover;'; need to add pollyfill for this
    }
}

// Apply border radius - default is 8px

@mixin border-radius($borderRadius: 8px) {
    border-radius: $borderRadius;
}

// button eleemnt reset

@mixin button-reset() {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

// Navbar drop shadow

@mixin navbar-drop-shadow() {
    box-shadow: 0 2px 10px 0 rgba(5, 5, 5, 0.15);
}

// Custom input

@mixin customInput($color: $gray) {
    height: 55px;
    padding: 15px 20px;
    background-color: transparent;
    border: solid 1px $color;

    &:focus {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
    }
}
