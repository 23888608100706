/**
 * Arlo SFCC Static
 * Main stylesheet.
 */
/* import theme */
/* ------ Animations ------ */
/*
 * Pulsing animation for PDP alarm rings
 *
*/
@keyframes pulse {
  0% {
    transform: scale(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0; } }

/**
* For hover states
*
* Usage:
* .selector {
*   @include on-hover {
*     opacity: 90%
*   };
* }
*/
/* ------ colors ------ */
/* ------ Primarys ------ */
/* ----- Secondary ------ */
/* ----- Tertiary ------- */
/* ------ defaults ------ */
/**
    font-face
    Include required file types for custom webfont rendering.
 */
/**
    Include your own fonts here!
 */
@font-face {
  font-family: "suisseintl";
  src: url("../assets/fonts/SuisseIntl/SuisseIntl-Regular.ttf") format("truetype"), url("../assets/fonts/SuisseIntl/SuisseIntl-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "suisseintl";
  src: url("../assets/fonts/SuisseIntl/SuisseIntl-Medium.ttf") format("truetype"), url("../assets/fonts/SuisseIntl/SuisseIntl-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: "suisseintl";
  src: url("../assets/fonts/SuisseIntl/SuisseIntl-SemiBold.ttf") format("truetype"), url("../assets/fonts/SuisseIntl/SuisseIntl-SemiBold.otf") format("opentype");
  font-style: normal;
  font-weight: 600; }

b,
dt,
strong {
  font-family: suisseintl, helvetica, arial, sans-serif; }

ol li,
ul li,
ol ol li,
ul ol li {
  font-family: suisseintl, helvetica, arial, sans-serif; }

/* ------ base form styling ------ */
/* ------ text input fields ------ */
input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
  height: 55px;
  padding: 15px 20px;
  background-color: transparent;
  border: solid 1px #c5c9cc; }
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=tel]:focus,
  input[type=password]:focus,
  textarea:focus {
    color: inherit;
    background-color: inherit;
    border-color: inherit; }

.form-control {
  font-family: suisseintl, helvetica, arial, sans-serif;
  border-radius: 8px; }

.form-float-container {
  position: relative;
  color: #384952; }
  .form-float-container.active label {
    transform: translate(0, 5px) scale(0.75); }
  .form-float-container .form-control-label {
    font-size: 16px;
    font-family: suisseintl, helvetica, arial, sans-serif;
    color: #384952;
    position: absolute;
    left: 20px;
    transform-origin: top left;
    transform: translate(0, 14px) scale(1);
    transition: all 150ms ease-in-out;
    pointer-events: none; }
  .form-float-container .form-control {
    padding: 25px 20px 5px; }

/* ------ checkbox/radio base styling ------ */
.custom-control {
  position: relative;
  display: flex;
  align-items: center;
  min-height: auto;
  padding-left: 40px; }

.custom-control-label::after,
.custom-control-label::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -36px;
  display: block;
  width: 18px;
  height: 18px;
  color: #384952; }

.custom-control-label::after {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%; }

/* ------ checkbox/radio unchedked state ------ */
.custom-radio .custom-control-input ~ .custom-control-label::after {
  background-image: none;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #384952;
  color: #384952; }

.custom-checkbox .custom-control-input ~ .custom-control-label::after {
  background: none;
  border: solid 1px #c5c9cc;
  border-radius: 3px;
  line-height: 1.2;
  padding-left: 5px; }

/* ------ checkbox/radio checked state ------ */
.custom-radio .custom-control-input ~ .custom-control-label {
  font-family: suisseintl, helvetica, arial, sans-serif; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #384952; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: 'x';
  background: none; }

/* ------ custom select ------ */
.custom-select {
  font-family: suisseintl, helvetica, arial, sans-serif; }

/* ------ Select2 custom dropdowns ------ */
.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none; }

.select2-container--open .select2-selection {
  border-bottom-color: #fff !important; }

.select2-container--open .select2-selection__arrow {
  transform: translateY(-50%) rotate(180deg) !important; }

.select2-container .select2-selection {
  height: 55px;
  padding: 15px 45px 15px 20px;
  border-color: solid 1px #c5c9cc;
  border-radius: 8px; }
  .select2-container .select2-selection:focus {
    outline: none; }
  .select2-container .select2-selection__rendered {
    padding: 0 !important; }
    .select2-container .select2-selection__rendered:focus {
      outline: none; }
  .select2-container .select2-selection__arrow {
    height: 25px !important;
    top: 50% !important;
    right: 23px !important;
    transform: translateY(-50%);
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==) no-repeat center center; }
    .select2-container .select2-selection__arrow b {
      display: none; }

.select2-dropdown {
  top: -1px;
  border: solid 1px #c5c9cc;
  border-radius: 8px; }

.select2-results__option {
  padding: 5px 20px; }
  .select2-results__option--selected, .select2-results__option--highlighted {
    background-color: transparent !important;
    color: inherit !important; }

.select2-selection.bluegray {
  background-color: transparent;
  border-color: #fff; }
  .select2-selection.bluegray .select2-selection__rendered {
    color: #fff; }
  .select2-selection.bluegray .select2-selection__arrow {
    background: url(../assets/svg/dropdown-arrow-white.svg) no-repeat center center; }

.select2-dropdown.bluegray {
  background-color: #8c9ba4;
  border-color: #fff;
  color: #fff; }

.select2-selection.lighttint,
.select2-dropdown.lighttint {
  background-color: #f8f9fa; }

/* ============================================================================
 * Breakpoints
*/
/* ============================================================================
 * media feature expressions
*/
/* ============================================================================
 * Breakpoint Mixins
*/
/*
 * mixin to query tablet width and up
 *
 * @requires $tablet-up
*/
/*
 * mixin to query laptop width and up
 *
 * @requires $laptop-up
*/
/*
 * mixin to query desktop width and up
 *
 * @requires $desktop-up
*/
/*
 * mixin to query widescreen width and up
 *
 * @requires $widescreen-up
*/
/* import vendors */
@media (hover: hover) {
  .slick-slider:hover .slick-arrow {
    visibility: visible;
    opacity: 0.6; }
  .slick-slider:hover .slick-disabled {
    opacity: 0;
    visibility: hidden; } }

.slick-slider .slick-arrow {
  width: 63px;
  height: 44px;
  transform: translate(0, -50%);
  background-color: #5a6871;
  transition: all 250ms ease;
  border-radius: 27.5px;
  visibility: hidden;
  opacity: 0; }
  @media (hover: hover) {
    .slick-slider .slick-arrow:hover {
      background-color: #5a6871;
      opacity: 1; } }
  .slick-slider .slick-arrow.slick-disabled {
    opacity: 0; }
  .slick-slider .slick-arrow::before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAiCAYAAAFTiQvnAAAABGdBTUEAALGPC/xhBQAAAoZJREFUWAnlmD1IVWEYxz03EU3CtMFIsKLEKXARysFqcdE9gwzaaiiChpagIRrcBcFFoamlxaktqKHRwdHAFkEEF1NEydvvOT1PvNI5556P9+gLPvC/79fz/M6fc+97z3tvW1veaBKSG8mLDaSfGrmSUqvjBRCd0ok8sJKuBHYYHSatZc6Zn0ZmVpFFIxapaZ1bmCoFRFdrtGaQfIBGZShvk9zLdl3LaoayFuM1YDNoQwaZRJI+knM+iqIrcWXaC4nr6Fna+rF5EjuOTZQZAHmKNsvUtqwBfBlZxDuzZVHRBKPTDhStzZUPeEsv8lAK7AvmLv37uQjpSYMsPUF7fDS609NyruDynTpdcUtKfwkA+y5AYBdwKDHiggv3YS0KkJgoXHwqBTg9Qne8XxzoJpJ4XAd85S+7OVsH/JPCl+uAv1f4ah3waYVv1QEfVfiBweWBc5XBuk14aJvswkY7Lz+BvwVYenurmde0coqZ1HH1BmOHeituVacpQYHS9HuBAupwoF2+oH0O1M8zD+CQQn97cWkQhf6ysbcW8DVvsKogMYOqn5aqGslTj9FFZLFLx9/GzGOgTA4mb6If5ppWnpqvyrBOtAaTPegLcmOJQeaR/kRNJl0Mg+fQguua/jfUm5Qf1BwmnyP5iFjI757hoEwmmcHkBNox19r/7yz772ufhHFA91DVx3SSnyJzl0h+hC5qkZzqX3CMmJNxbBizclhZQ6F++PfxdgPTG7ThBDduCu0hi206Y+E4VCeYeoncjbfG+HpQRjHUQPPIja8MekIz2o2pz65L+h9QWPsHQwNoFbnxJqi7KWZwJ/932g9jMSt/3j0IzqgZwpz8fyYbSkzftvkz0/4BsT1vCRelHcEAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-size: 20px 15px;
    background-position: center;
    opacity: 1; }

.slick-slider .slick-prev {
  left: -32.5px; }

.slick-slider .slick-next {
  right: -32.5px; }

.slick-slider .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center; }
  .slick-slider .slick-dots li {
    width: 10px;
    height: 15px;
    margin: 0 4.5px; }
    .slick-slider .slick-dots li button {
      width: 100%;
      height: 100%;
      min-width: 8px; }
      .slick-slider .slick-dots li button::before {
        opacity: 0.5;
        background: #2b3234;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 250ms ease; }
    .slick-slider .slick-dots li.slick-active button::before {
      height: 14px;
      opacity: 1;
      border-radius: 4px; }

.slick-slider--light .slick-dots li button::before {
  background: #fff; }

/* import partials */
.header {
  height: 70px;
  padding: 0;
  z-index: 99; }
  @media only screen and (min-width: 1200px) {
    .header {
      height: 80px; } }
  .header a {
    color: #384952;
    font-weight: 500; }
  .header__banner {
    display: none;
    min-height: 48px;
    background-color: #3c9;
    color: #fff;
    align-items: center;
    text-align: center; }
    .header__banner.active {
      display: flex; }
    .header__banner a {
      color: #fff; }
    .header__banner-content {
      padding: 12px 0; }
  .header__container {
    position: relative;
    display: flex;
    height: 70px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(5, 5, 5, 0.15); }
    @media only screen and (min-width: 1200px) {
      .header__container {
        height: 80px; } }
    .header__container.fixed {
      position: fixed;
      top: 0;
      z-index: 999; }
  .header__items {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%; }
    .header__items > * {
      position: relative; }
  .header__mobile-hamburger {
    display: block;
    cursor: pointer;
    line-height: 0; }
    @media only screen and (min-width: 1200px) {
      .header__mobile-hamburger {
        display: none; } }
    .header__mobile-hamburger.active .hamburger-inner {
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, -10px, 0) rotate(-45deg); }
      .header__mobile-hamburger.active .hamburger-inner::before {
        top: 0;
        transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
        transform: rotate(-90deg); }
      .header__mobile-hamburger.active .hamburger-inner::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
        opacity: 0; }
    .header__mobile-hamburger .hamburger-box {
      perspective: 80px;
      position: relative;
      display: inline-block;
      width: 26px;
      height: 22px; }
    .header__mobile-hamburger .hamburger-inner,
    .header__mobile-hamburger .hamburger-inner::before,
    .header__mobile-hamburger .hamburger-inner::after {
      position: absolute;
      width: 26px;
      height: 2px;
      transition-property: transform;
      border-radius: 4px;
      background-color: #2b3234; }
    .header__mobile-hamburger .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition-duration: 0.13s; }
      .header__mobile-hamburger .hamburger-inner::before, .header__mobile-hamburger .hamburger-inner::after {
        content: '';
        display: block; }
      .header__mobile-hamburger .hamburger-inner::before {
        top: -8px;
        transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
      .header__mobile-hamburger .hamburger-inner::after {
        bottom: -8px;
        top: -16px;
        transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear; }
  .header__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; }
    @media only screen and (min-width: 1200px) {
      .header__logo {
        position: static;
        transform: translateY(-5px); }
        .header__logo img {
          width: 94px;
          height: auto; } }
  .header__nav {
    display: none;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 5; }
    @media only screen and (min-width: 1200px) {
      .header__nav {
        display: flex; } }
  .header__nav-list {
    margin: 0 auto;
    padding: 0;
    list-style-type: none; }
  .header__nav-item {
    display: inline-block; }
    .header__nav-item:not(:last-child) {
      margin-right: 37px; }
  .header__utilities {
    display: flex;
    align-items: center;
    z-index: 10;
    margin-left: auto; }
    @media only screen and (min-width: 1400px) {
      .header__utilities {
        transform: translateX(35px); } }
  .header__utilities-list {
    margin: 0;
    padding: 0; }
  .header__utilities-item {
    position: relative;
    display: inline-block; }
    .header__utilities-item.item-support {
      display: none;
      margin-right: 37px; }
      @media only screen and (min-width: 1200px) {
        .header__utilities-item.item-support {
          display: inline-block; } }
    .header__utilities-item.item-sign-in {
      display: none;
      margin-right: 37px; }
      @media only screen and (min-width: 1200px) {
        .header__utilities-item.item-sign-in {
          display: inline-block; } }
      .header__utilities-item.item-sign-in:hover .popover, .header__utilities-item.item-sign-in:focus .popover {
        visibility: visible;
        opacity: 1; }
      .header__utilities-item.item-sign-in[data-logged-in=true] .popover {
        right: -10px; }
    .header__utilities-item.item-search {
      opacity: 0;
      visibility: hidden;
      transition: all 0.25s ease-in-out;
      position: absolute;
      right: 0; }
      @media only screen and (min-width: 1200px) {
        .header__utilities-item.item-search {
          position: static;
          visibility: visible;
          opacity: 1;
          margin-right: 29px; } }
      .menu-open .header__utilities-item.item-search {
        visibility: visible;
        opacity: 1; }
    .header__utilities-item.item-cart {
      transition: all 0.25s ease-in-out; }
      .menu-open .header__utilities-item.item-cart {
        opacity: 0;
        visibility: hidden; }
      .header__utilities-item.item-cart.indicator::after {
        content: '';
        position: absolute;
        top: 0;
        right: -8px;
        height: 8px;
        width: 8px;
        animation: cart-icon-pulse 4s infinite;
        background-color: #3c9;
        border-radius: 50%; }
      .header__utilities-item.item-cart a {
        color: inherit; }
  .header .cart-count {
    position: absolute;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 10px;
    line-height: 14px;
    font-weight: bold;
    left: 50%;
    top: 62%;
    transform: translate(-50%, -50%); }
  .header__mobile-nav {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    height: 0;
    background-color: #fff;
    transition: height 0.5s ease-in-out;
    overflow: hidden;
    z-index: 9999; }
    .menu-open .header__mobile-nav {
      height: 100vh; }
    .header__mobile-nav-container {
      padding-top: 30px; }
  .header__mobile-nav-list, .header__mobile-utilities-list {
    margin: 0;
    padding: 0; }
    .header__mobile-nav-list-item, .header__mobile-utilities-list-item {
      list-style-type: none; }
  .header__mobile-nav-list {
    margin-bottom: 5px; }
    .header__mobile-nav-list-item {
      margin-bottom: 15px; }
  .header__mobile-utilities-list-item {
    margin-bottom: 10px; }
  .header .popover {
    position: absolute;
    z-index: 10;
    display: block;
    top: calc(100% + 10px);
    left: auto;
    right: -30px;
    padding: 0;
    width: 250px;
    font-family: suisseintl, helvetica, arial, sans-serif;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 0 15px 0 rgba(140, 155, 164, 0.2);
    border: 0;
    border-radius: 8px; }
    .header .popover::before {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      right: 0;
      z-index: -1;
      -webkit-filter: drop-shadow(0 0 5px rgba(140, 155, 164, 0.2));
      filter: drop-shadow(0 0 5px rgba(140, 155, 164, 0.2));
      width: 0;
      height: 0;
      border-width: 0 55px 31px;
      border-style: solid;
      border-color: transparent transparent white;
      border-image: initial; }
    .header .popover__content {
      padding: 25px 20px;
      background-color: #fff;
      border-radius: 8px; }
      .header .popover__content-headline {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 15px;
        margin-bottom: 18px;
        border-bottom: solid 1px #dfe3e6; }
        .header .popover__content-headline span {
          display: block;
          font-size: 24px;
          line-height: 26px;
          color: #3c9;
          font-weight: 600; }
    .header .popover .nav {
      flex-direction: column; }
      .header .popover .nav li {
        margin-bottom: 5px; }
      .header .popover .nav a {
        text-decoration: none;
        font-size: 16px;
        line-height: 14px; }

@keyframes cart-icon-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5); }
  50% {
    box-shadow: 0 0 0 0 #3c9; }
  to {
    box-shadow: 0 0 0 8px transparent; } }

.footer {
  background-color: #384952;
  color: #fff;
  padding: 50px 0 63px; }
  @media only screen and (min-width: 768px) {
    .footer {
      padding: 50px 0; } }
  @media only screen and (min-width: 992px) {
    .footer {
      padding: 80px 0 58px; } }
  .footer a {
    text-decoration: none;
    font-weight: 400;
    color: #fff; }
  .footer__newsletter {
    margin-bottom: 53px; }
    @media only screen and (min-width: 768px) {
      .footer__newsletter {
        margin-bottom: 35px; } }
    @media only screen and (min-width: 992px) {
      .footer__newsletter {
        margin-bottom: 97px; } }
    .footer__newsletter-form {
      position: relative; }
    .footer__newsletter-heading {
      margin-bottom: 26px; }
      @media only screen and (min-width: 768px) {
        .footer__newsletter-heading {
          display: none; } }
    .footer__newsletter form input[type=email] {
      background-color: #384952 !important;
      height: 55px;
      width: 100%;
      border: solid 1px #8c9ba4;
      border-radius: 40px;
      outline-style: inherit;
      padding: 0 25px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      /* stylelint-disable-next-line */ }
      @media only screen and (min-width: 768px) {
        .footer__newsletter form input[type=email] {
          font-size: 22px;
          height: 80px;
          padding: 0 43px; } }
      .footer__newsletter form input[type=email]::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff; }
        @media only screen and (min-width: 768px) {
          .footer__newsletter form input[type=email]::-webkit-input-placeholder {
            display: none;
            opacity: 0; } }
      .footer__newsletter form input[type=email]::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff; }
        @media only screen and (min-width: 768px) {
          .footer__newsletter form input[type=email]::-moz-placeholder {
            display: none;
            opacity: 0; } }
      .footer__newsletter form input[type=email]:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff; }
        @media only screen and (min-width: 768px) {
          .footer__newsletter form input[type=email]:-ms-input-placeholder {
            display: none;
            opacity: 0; } }
      .footer__newsletter form input[type=email]:-moz-placeholder {
        /* Firefox 18- */
        color: #fff; }
        @media only screen and (min-width: 768px) {
          .footer__newsletter form input[type=email]:-moz-placeholder {
            display: none;
            opacity: 0; } }
      .footer__newsletter form input[type=email]:focus ~ span {
        transition: opacity 0.25s ease-in-out;
        visibility: hidden;
        opacity: 0; }
    .footer__newsletter form button[type=submit] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      color: #fff;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      width: initial;
      min-width: inherit;
      font-size: 16px;
      font-weight: 600;
      /* stylelint-disable-next-line */ }
      @media only screen and (min-width: 768px) {
        .footer__newsletter form button[type=submit] {
          font-size: 22px;
          height: 80px; } }
      @media only screen and (min-width: 992px) {
        .footer__newsletter form button[type=submit] {
          right: 45px; } }
      .footer__newsletter form button[type=submit][disabled] {
        color: #8c9ba4; }
      .footer__newsletter form button[type=submit]::focus {
        outline: none; }
    .footer__newsletter form span {
      display: none; }
      @media only screen and (min-width: 768px) {
        .footer__newsletter form span {
          text-rendering: optimizeLegibility;
          font-family: suisseintl, helvetica, arial, sans-serif;
          margin: 0;
          font-size: 22px;
          line-height: 24px;
          font-weight: 600;
          display: block;
          position: absolute;
          top: 50%;
          left: 43px;
          transform: translateY(-50%);
          pointer-events: none;
          visibility: visible;
          opacity: 1;
          transition: opacity 0.5s ease-in-out; } }
  @media only screen and (min-width: 768px) and (min-width: 992px) {
    .footer__newsletter form span {
      font-size: 24px;
      line-height: 26px; } }
  .footer__nav-col:first-child {
    margin-bottom: 55px; }
    @media only screen and (min-width: 768px) {
      .footer__nav-col:first-child {
        margin-bottom: 0; } }
  .footer__nav-col:last-child {
    margin-top: 55px; }
    @media only screen and (min-width: 768px) {
      .footer__nav-col:last-child {
        margin-top: 0; } }
  .footer__nav-col-header {
    color: #8c9ba4;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .footer__nav-col-header {
        margin-bottom: 34px; } }
  .footer__nav-col-list-item {
    margin-bottom: 12px; }
  .footer__nav-col-categories .footer__nav-col-list-item {
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .footer__nav-col-categories .footer__nav-col-list-item {
        margin-bottom: 14px; } }
  .footer__utilities {
    align-items: center;
    margin-top: 57px; }
    @media only screen and (min-width: 768px) {
      .footer__utilities {
        margin-top: 50px; } }
    @media only screen and (min-width: 992px) {
      .footer__utilities {
        margin-top: 96px; } }
    .footer__utilities-country {
      display: flex;
      align-items: center; }
      .footer__utilities-country img {
        display: block;
        width: 20px;
        height: auto;
        margin-right: 10px; }
      .footer__utilities-country button {
        display: flex;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit; }
    .footer__utilities-copyright {
      margin-top: 20px; }
      @media only screen and (min-width: 992px) {
        .footer__utilities-copyright {
          margin-top: 0;
          margin-right: 15px;
          display: inline-block; } }
    .footer__utilities-links {
      display: inline-block;
      margin: 0; }
    .footer__utilities-link {
      display: inline-block; }
      .footer__utilities-link:not(:last-child) {
        margin-right: 15px; }
    .footer__utilities-social {
      margin: 0;
      margin-top: 36px; }
      @media only screen and (min-width: 768px) {
        .footer__utilities-social {
          margin-top: 0; } }
      .footer__utilities-social > * {
        display: inline-block; }
        .footer__utilities-social > *:not(:last-child) {
          margin-right: 38px; }
      .footer__utilities-social a {
        font-size: 25px; }

.minicart {
  position: fixed;
  top: -3px;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.04) -4px 0 16px 0;
  z-index: 999;
  transform: translateX(103%);
  background: white;
  transition: transform 500ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s;
  padding: 30px 30px 35px; }
  @media only screen and (min-width: 768px) {
    .minicart {
      width: 600px;
      padding: 30px 83px 35px; } }
  .minicart.active {
    transform: translateX(0);
    transition: transform 800ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s; }
  .minicart__container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%; }
  .minicart__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
  .minicart__header {
    display: flex;
    align-items: center;
    margin-bottom: 34px; }
    @media only screen and (min-width: 992px) {
      .minicart__header {
        margin-bottom: 93px; } }
    .minicart__header h4 {
      margin-right: 11px;
      font-size: 22px; }
      @media only screen and (min-width: 992px) {
        .minicart__header h4 {
          margin-right: 16px;
          font-size: 24px; } }
  .minicart__count {
    color: #384952; }
  .minicart__items {
    height: calc(100% - 300px);
    overflow: scroll; }
    @media only screen and (min-width: 992px) {
      .minicart__items {
        height: calc(100% - 360px); } }
  .minicart__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    .minicart__footer-totals {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px; }
    .minicart__footer-disclaimer {
      margin-bottom: 21px; }
    .minicart__footer-buttons > * {
      width: 100%; }
      .minicart__footer-buttons > *:first-child {
        margin-bottom: 10px; }

.search-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.04) -4px 0 16px 0;
  z-index: 9999;
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s; }
  .search-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    transform: translateY(-103%);
    transition: transform 500ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s; }
  @media only screen and (min-width: 992px) {
    .search-bar {
      max-height: 760px; } }
  .search-bar:not(.active) {
    pointer-events: none; }
  .search-bar.active {
    opacity: 1;
    visibility: visible;
    transition: all 800ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s; }
    .search-bar.active::before {
      transform: translateY(0); }
  .search-bar .container {
    position: relative;
    max-height: 100vh;
    overflow: scroll; }
  .search-bar__close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 10; }
  .search-bar__container {
    position: relative;
    z-index: 1;
    padding: 30px 0 23px; }
  .search-bar__input {
    margin-top: 100px; }
    @media only screen and (min-width: 768px) {
      .search-bar__input {
        margin-top: 0; } }
    .search-bar__input input[type=search] {
      appearance: none;
      border: 0;
      border-bottom: solid 1px #5a6871;
      width: 100%;
      color: #2b3234;
      text-rendering: optimizeLegibility;
      font-family: suisseintl, helvetica, arial, sans-serif;
      margin: 0;
      font-size: 22px;
      line-height: 24px;
      font-weight: 600; }
      @media only screen and (min-width: 992px) {
        .search-bar__input input[type=search] {
          font-size: 24px;
          line-height: 26px; } }
      .search-bar__input input[type=search]:focus {
        outline: none; }
      .search-bar__input input[type=search]::-webkit-search-cancel-button {
        display: none; }
      @media only screen and (min-width: 992px) {
        .search-bar__input input[type=search] {
          max-width: 650px; } }
      .search-bar__input input[type=search]::placeholder {
        opacity: 0.3;
        font-weight: 300; }
  .search-bar__recent-searches {
    padding-top: 86px;
    padding-bottom: 40px; }
    .search-bar__recent-searches a {
      text-decoration: none; }
    .search-bar__recent-searches-intro {
      margin-bottom: 30px; }
    .search-bar__recent-searches-list-item {
      margin-bottom: 20px; }
  .search-bar__results {
    padding-top: 30px; }
    .search-bar__results-intro {
      margin-bottom: 40px; }
      @media only screen and (min-width: 992px) {
        .search-bar__results-intro {
          margin-bottom: 27px; } }
      .search-bar__results-intro h5 {
        margin-bottom: 5px;
        color: #2b3234; }
    .search-bar__results-dym span {
      font-weight: bold; }
    @media only screen and (min-width: 992px) {
      .search-bar__results-container {
        display: flex; } }
    @media only screen and (min-width: 992px) {
      .search-bar__results-left {
        width: 66%;
        padding-right: 60px; } }
    @media only screen and (min-width: 992px) {
      .search-bar__results-right {
        width: 33%; } }
    .search-bar__results-headline {
      color: #2b3234;
      margin-bottom: 21px; }
      @media only screen and (min-width: 992px) {
        .search-bar__results-headline {
          margin-bottom: 30px; } }
      .search-bar__results-headline span {
        font-size: 16px;
        line-height: 24px;
        color: #384952;
        font-weight: 400; }
  @media only screen and (min-width: 768px) {
    .search-bar__products-results {
      display: flex;
      margin-left: -5px;
      margin-right: -5px; } }
  .search-bar__products-result {
    display: flex;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .search-bar__products-result {
        display: block;
        width: 33%;
        padding: 0 5px;
        margin-bottom: 0; } }
    .search-bar__products-result:hover .product-image {
      opacity: 0;
      visibility: hidden; }
    .search-bar__products-result a {
      text-decoration: none; }
    .search-bar__products-result .item-image {
      position: relative;
      margin-right: 15px; }
      @media only screen and (min-width: 768px) {
        .search-bar__products-result .item-image {
          margin-bottom: 10px; } }
      .search-bar__products-result .item-image img {
        margin: 0;
        transition: all 0.5s ease;
        border-radius: 8px; }
        @media only screen and (min-width: 768px) {
          .search-bar__products-result .item-image img {
            width: 100%;
            height: auto; } }
      .search-bar__products-result .item-image .product-image-rollover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1; }
    .search-bar__products-result .item-details a {
      color: inherit; }
    .search-bar__products-result .line-item-name {
      width: 100%;
      text-overflow: initial;
      white-space: initial;
      font-family: inherit;
      margin-bottom: 9px; }
    .search-bar__products-result .item-attributes {
      margin-bottom: 14px; }
      @media only screen and (min-width: 992px) {
        .search-bar__products-result .item-attributes {
          margin-bottom: 10px; } }
    .search-bar__products-result .price {
      font-family: inherit;
      color: inherit; }
      .search-bar__products-result .price h5 {
        display: inline-block;
        color: #2b3234; }
  .search-bar__categories-result, .search-bar__pages-result {
    margin-bottom: 5px; }
  .search-bar__categories a, .search-bar__pages a {
    text-decoration: none; }
  .search-bar__categories {
    margin-bottom: 40px; }
    @media only screen and (min-width: 992px) {
      .search-bar__categories {
        margin-bottom: 60px; } }
  .search-bar__all-results {
    margin-top: 61px;
    text-align: center;
    margin-bottom: 100px; }
    @media only screen and (min-width: 992px) {
      .search-bar__all-results {
        width: 66%;
        padding-right: 60px;
        margin-bottom: 0; } }

/**
 * Partial: Product line item / info
 */
.product-line-item,
.product-info {
  display: flex; }
  .product-line-item:not(:last-child),
  .product-info:not(:last-child) {
    padding-bottom: 19px;
    margin-bottom: 19px;
    border-bottom: solid 1px #dfe3e6; }
  .product-line-item button,
  .product-info button {
    width: inherit;
    min-width: inherit;
    height: inherit;
    background-color: inherit;
    padding: 0;
    cursor: pointer;
    border: none;
    border-radius: 0; }
    .product-line-item button:hover, .product-line-item button:focus, .product-line-item button:not(:disabled):not(.disabled):active, .product-line-item button:not(:disabled):not(.disabled):active:focus,
    .product-info button:hover,
    .product-info button:focus,
    .product-info button:not(:disabled):not(.disabled):active,
    .product-info button:not(:disabled):not(.disabled):active:focus {
      background-color: inherit;
      outline: none; }
  .product-line-item .item-image,
  .product-info .item-image {
    width: 120px; }
  .product-line-item .product-image,
  .product-info .product-image {
    height: 110px;
    width: 110px;
    margin: 0;
    border-radius: 8px; }
  .product-line-item .item-details,
  .product-info .item-details {
    width: calc(100% - 220px);
    display: flex;
    flex-direction: column; }
    .product-line-item .item-details a,
    .product-info .item-details a {
      text-decoration: none; }
    .product-line-item .item-details .line-item-name,
    .product-info .item-details .line-item-name {
      width: 100%;
      text-overflow: initial;
      white-space: initial;
      font-family: inherit;
      margin-bottom: 4px; }
  .product-line-item .item-attributes,
  .product-info .item-attributes {
    color: #5a6871;
    padding-right: 10px; }
    .product-line-item .item-attributes .flagged,
    .product-info .item-attributes .flagged {
      color: #ab1f1f; }
    .product-line-item .item-attributes-notices,
    .product-info .item-attributes-notices {
      margin-top: 5px; }
  .product-line-item .item-soldout,
  .product-info .item-soldout {
    margin-top: auto;
    color: #ab1f1f; }
  .product-line-item .item-quantity,
  .product-info .item-quantity {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    color: #384952; }
  .product-line-item .item-pricing,
  .product-info .item-pricing {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    width: 100px; }
  .product-line-item .price,
  .product-info .price {
    color: #384952; }
    .product-line-item .price .strike-through,
    .product-info .price .strike-through {
      color: #8c9ba4; }
    .product-line-item .price .sales,
    .product-info .price .sales {
      margin-right: 0; }
  .product-line-item .line-item-quantity,
  .product-info .line-item-quantity {
    margin-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
    border: solid 1px #c5c9cc;
    border-radius: 8px;
    height: 35px;
    width: 75px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .product-line-item .line-item-quantity button,
    .product-info .line-item-quantity button {
      width: 15px;
      height: 100%;
      color: #384952;
      font-weight: 400; }
      .product-line-item .line-item-quantity button:first-child,
      .product-info .line-item-quantity button:first-child {
        margin-left: 10px; }
      .product-line-item .line-item-quantity button:last-child,
      .product-info .line-item-quantity button:last-child {
        margin-right: 10px; }
    .product-line-item .line-item-quantity input[type=number],
    .product-info .line-item-quantity input[type=number] {
      border: none;
      height: 100%;
      text-align: center;
      width: calc(100% - 50px);
      color: #2b3234; }
      .product-line-item .line-item-quantity input[type=number]::-webkit-inner-spin-button, .product-line-item .line-item-quantity input[type=number]::-webkit-outer-spin-button,
      .product-info .line-item-quantity input[type=number]::-webkit-inner-spin-button,
      .product-info .line-item-quantity input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0; }
      .product-line-item .line-item-quantity input[type=number]:focus,
      .product-info .line-item-quantity input[type=number]:focus {
        outline: none; }
  .product-line-item .remove-line-item,
  .product-info .remove-line-item {
    position: absolute;
    bottom: 4px;
    right: 100%;
    /* stylelint-disable-next-line */ }
    .product-line-item .remove-line-item button,
    .product-info .remove-line-item button {
      color: #000; }
  .product-line-item .line-item-total-price,
  .product-info .line-item-total-price {
    display: flex;
    align-items: center;
    margin-top: auto; }
    .product-line-item .line-item-total-price .line-item-price-info,
    .product-info .line-item-total-price .line-item-price-info {
      margin: 0; }
    .product-line-item .line-item-total-price-label,
    .product-info .line-item-total-price-label {
      font-size: inherit;
      color: #2b3234;
      font-weight: 600;
      text-transform: uppercase;
      margin-right: 10px; }
  @media only screen and (min-width: 992px) {
    .cart-page .product-line-item .item-image, .cart-page
    .product-info .item-image {
      width: 165px; } }
  @media only screen and (min-width: 992px) {
    .cart-page .product-line-item .product-image, .cart-page
    .product-info .product-image {
      width: 145px;
      height: 145px; } }
  @media only screen and (min-width: 992px) {
    .cart-page .product-line-item .item-details, .cart-page
    .product-info .item-details {
      width: calc(100% - 265px); } }

.breadcrumb {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0; }
  @media only screen and (min-width: 992px) {
    .breadcrumb {
      margin-top: 40px; } }
  .breadcrumb-item {
    font-size: 14px;
    line-height: 17px;
    font-family: suisseintl, helvetica, arial, sans-serif; }
    @media only screen and (min-width: 992px) {
      .breadcrumb-item {
        font-size: 16px;
        line-height: 24px; } }
    .breadcrumb-item:last-child a {
      color: #384952;
      text-decoration: none; }
      .breadcrumb-item:last-child a:hover {
        color: inherit; }
    .breadcrumb-item a {
      color: #384952; }
      .breadcrumb-item a:hover {
        color: inherit;
        text-decoration: none; }
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 15px; }
      .breadcrumb-item + .breadcrumb-item::before {
        content: '';
        width: 20px;
        height: 1px;
        background-color: #384952;
        vertical-align: middle;
        margin-right: 15px; }

/**
 * Component: Region Selector
 */
/* --- namespace --- */
/* --- styles --- */
.region-selector .modal__box {
  min-width: 315px;
  padding: 86px 27px 89px 20px; }
  @media only screen and (min-width: 768px) {
    .region-selector .modal__box {
      padding: 57px 60px 47px 47px; } }

.region-selector__list {
  display: none; }
  @media only screen and (min-width: 768px) {
    .region-selector__list {
      display: block; } }

.region-selector__item {
  display: flex;
  margin-bottom: 20px;
  color: #384952;
  font-size: 16px;
  line-height: 16px; }
  .region-selector__item a {
    text-decoration: none; }

.region-selector__flag {
  margin-right: 19px; }

@media only screen and (min-width: 768px) {
  .region-selector__select {
    display: none; } }

.region-selector__select-label {
  position: absolute;
  top: 30px;
  left: 20px; }

/* --- GRID ----------------------------------------------- */
.container {
  margin: 0 auto;
  max-width: 1294px; }
  .container--outer {
    padding: 0 30px;
    max-width: 1324px; }

.modal-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #2b3234;
  z-index: 999;
  transition: all 0.5s ease-in-out; }
  .modal-overlay.active {
    visibility: visible;
    opacity: 0.5; }

body {
  color: #384952;
  font-family: suisseintl, helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0; }
  body.no-scroll {
    overflow: hidden; }

/* --- TYPOGRAPHY ----------------------------------------- */
h1,
.h1 {
  text-rendering: optimizeLegibility;
  font-family: suisseintl, helvetica, arial, sans-serif;
  margin: 0;
  font-size: 44px;
  line-height: 47px;
  font-weight: 600; }
  @media only screen and (min-width: 992px) {
    h1,
    .h1 {
      font-size: 60px;
      line-height: 60px; } }

h2,
.h2 {
  text-rendering: optimizeLegibility;
  font-family: suisseintl, helvetica, arial, sans-serif;
  margin: 0;
  font-size: 35px;
  line-height: 38px;
  font-weight: 600; }
  @media only screen and (min-width: 992px) {
    h2,
    .h2 {
      font-size: 45px;
      line-height: 48px; } }

h3,
.h3 {
  text-rendering: optimizeLegibility;
  font-family: suisseintl, helvetica, arial, sans-serif;
  margin: 0;
  font-size: 27px;
  line-height: 30px;
  font-weight: 600; }
  @media only screen and (min-width: 992px) {
    h3,
    .h3 {
      font-size: 35px;
      line-height: 38px; } }

h4,
.h4 {
  text-rendering: optimizeLegibility;
  font-family: suisseintl, helvetica, arial, sans-serif;
  margin: 0;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600; }
  @media only screen and (min-width: 992px) {
    h4,
    .h4 {
      font-size: 24px;
      line-height: 26px; } }

h5,
.h5 {
  text-rendering: optimizeLegibility;
  font-family: suisseintl, helvetica, arial, sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600; }
  @media only screen and (min-width: 992px) {
    h5,
    .h5 {
      font-size: 18px;
      line-height: 20px; } }

h6,
.h6 {
  text-rendering: optimizeLegibility;
  font-family: suisseintl, helvetica, arial, sans-serif;
  margin: 0;
  font-size: 19px;
  line-height: 21px;
  font-weight: 600; }
  @media only screen and (min-width: 992px) {
    h6,
    .h6 {
      font-size: 21px;
      line-height: 23px;
      font-weight: 500; } }

p {
  font-size: 16px;
  line-height: 24px; }

.large-text {
  font-size: 18px;
  line-height: 30px; }

.small-text {
  font-size: 14px;
  line-height: 17px; }

.legal-text {
  font-size: 12px;
  line-height: 14px; }

.legal-bold-text {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600; }

a {
  font-family: inherit;
  color: #384952;
  transition: color 200ms ease; }
  @media (hover: hover) {
    a:hover {
      color: #3c9; } }

.txt-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  line-height: 1.2; }
  .txt-link::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -2px;
    display: block;
    border-bottom: 1px solid currentColor;
    margin-top: 2px;
    transform: scaleX(1);
    transform-origin: 0 50%;
    transition: transform 250ms ease-in-out; }
  @media (hover: hover) {
    .txt-link:hover {
      color: inherit;
      text-decoration: none; }
      .txt-link:hover::after {
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%; } }
  .txt-link.txt-link--inverse::after {
    content: '';
    display: block;
    transform: scaleX(0);
    transform-origin: 100% 50%; }
  @media (hover: hover) {
    .txt-link.txt-link--inverse:hover::after {
      transform: scaleX(1);
      transition: transform 250ms ease-in-out;
      transform-origin: 0 50%; } }

/* --- BUTTONS -------------------------------------------- */
.btn {
  position: relative;
  min-width: 230px;
  padding: 20px 40px;
  height: 55px;
  color: #fff;
  font-family: suisseintl, helvetica, arial, sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  background-color: #3c9;
  border: 0;
  border-radius: 27.5px;
  cursor: pointer;
  transition: background-color 200ms ease;
  overflow: hidden; }
  .btn span {
    position: relative;
    display: inline-block;
    transform: translateX(0);
    transition: all 0.5s cubic-bezier(0.16, 0.01, 0.13, 0.99); }
    .btn span::before {
      content: '';
      display: block;
      position: absolute;
      background-image: url(../assets/svg/btn-arrow-white.svg);
      background-repeat: no-repeat;
      height: 15px;
      width: 26px;
      opacity: 0;
      right: calc(100% + 20px);
      transform: scale(0.8) translateX(0);
      transition: all 0.5s cubic-bezier(0.16, 0.01, 0.13, 0.99); }
  .btn:hover, .btn:focus, .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled):active:focus {
    background-color: #3c9;
    box-shadow: none;
    color: #fff; }
    .btn:hover span, .btn:focus span, .btn:not(:disabled):not(.disabled):active span, .btn:not(:disabled):not(.disabled):active:focus span {
      transform: translateX(20px); }
      .btn:hover span::before, .btn:focus span::before, .btn:not(:disabled):not(.disabled):active span::before, .btn:not(:disabled):not(.disabled):active:focus span::before {
        opacity: 1;
        transform: scale(1) translateX(5px); }
  .btn[disabled] {
    pointer-events: none;
    background-color: #dfe3e6 !important;
    color: #8c9ba4 !important; }
  .btn.btn-primary {
    background-color: #3c9;
    box-shadow: none;
    border: 0; }
    .btn.btn-primary:hover {
      background-color: #3c9;
      border: 0; }
  .btn.btn-secondary {
    background-color: #8c9ba4;
    box-shadow: none;
    border: 0; }
    .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:not(:disabled):not(.disabled):active {
      background-color: #8c9ba4;
      border: 0;
      box-shadow: none; }
  .btn.btn-white {
    background-color: #fff;
    color: #2b3234;
    box-shadow: none;
    border: 0;
    /* stylelint-disable-next-line */ }
    .btn.btn-white span::before {
      background-image: url(../assets/svg/btn-arrow-dark.svg); }
    .btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white:not(:disabled):not(.disabled):active {
      background-color: #fff;
      color: #2b3234;
      border: 0; }

img {
  max-width: 100%;
  height: auto; }
