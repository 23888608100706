// -------------------------- Shared all headings <h1>-<h6>

@mixin heading {
    text-rendering: optimizeLegibility;
    font-family: $fontFamily-headings;
    // color: $fontColor-headings;
}

// -------------------------- Heading 1 <h1>

@mixin heading-1 {
    @include heading;

    margin: 0;
    font-size: 44px;
    line-height: 47px;
    font-weight: 600;

    @include at-laptop {
        font-size: 60px;
        line-height: 60px;
    }
}

// -------------------------- Heading 2 <h2>

@mixin heading-2 {
    @include heading;

    margin: 0;
    font-size: 35px;
    line-height: 38px;
    font-weight: 600;

    @include at-laptop {
        font-size: 45px;
        line-height: 48px;
    }
}

// -------------------------- Heading 3 <h3>

@mixin heading-3 {
    @include heading;

    margin: 0;
    font-size: 27px;
    line-height: 30px;
    font-weight: 600;

    @include at-laptop {
        font-size: 35px;
        line-height: 38px;
    }
}

// -------------------------- Heading 4 <h4>

@mixin heading-4 {
    @include heading;

    margin: 0;
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;

    @include at-laptop {
        font-size: 24px;
        line-height: 26px;
    }
}

// -------------------------- Heading 5 <h5>

@mixin heading-5 {
    @include heading;

    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    @include at-laptop {
        font-size: 18px;
        line-height: 20px;
    }
}

// -------------------------- Heading 6 <h6>

@mixin heading-6 {
    @include heading;

    margin: 0;
    font-size: 19px;
    line-height: 21px;
    font-weight: 600;

    @include at-laptop {
        font-size: 21px;
        line-height: 23px;
        font-weight: 500;
    }
}

// -------------------------- Body Copy <p>

@mixin body-copy {
    font-size: 16px;
    line-height: 24px;
}

@mixin body-copy-large {
    font-size: 18px;
    line-height: 30px;
}

@mixin body-copy-small {
    font-size: 14px;
    line-height: 17px;
}

@mixin body-copy-legal {
    font-size: 12px;
    line-height: 14px;
}

@mixin body-copy-legal-bold {
    @include body-copy-legal;

    font-weight: 600;
}

// -------------------------- Inline Link <a>

@mixin inline-link {
    font-family: inherit;
    color: $fontColor-links;
    transition: color 200ms ease;

    @include on-hover {
        color: $green;
    }
}

b,
dt,
strong {
    font-family: $fontFamily-base;
}

ol li,
ul li,
ol ol li,
ul ol li {
    font-family: $fontFamily-base;
}
