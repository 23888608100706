// slick overrides

$ns: '.slick-slider';

#{$ns} {
    @include on-hover {
        .slick-arrow {
            visibility: visible;
            opacity: 0.6;
        }

        .slick-disabled {
            opacity: 0;
            visibility: hidden;
        }
    }

    // Arrows

    .slick-arrow {
        width: 63px;
        height: 44px;
        transform: translate(0, -50%);
        background-color: $darkBlueGray;
        transition: all 250ms ease;
        @include border-radius(27.5px);

        visibility: hidden;
        opacity: 0;

        @include on-hover {
            background-color: $darkBlueGray;
            opacity: 1;
        }

        &.slick-disabled {
            opacity: 0;
        }

        &::before {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAiCAYAAAFTiQvnAAAABGdBTUEAALGPC/xhBQAAAoZJREFUWAnlmD1IVWEYxz03EU3CtMFIsKLEKXARysFqcdE9gwzaaiiChpagIRrcBcFFoamlxaktqKHRwdHAFkEEF1NEydvvOT1PvNI5556P9+gLPvC/79fz/M6fc+97z3tvW1veaBKSG8mLDaSfGrmSUqvjBRCd0ok8sJKuBHYYHSatZc6Zn0ZmVpFFIxapaZ1bmCoFRFdrtGaQfIBGZShvk9zLdl3LaoayFuM1YDNoQwaZRJI+knM+iqIrcWXaC4nr6Fna+rF5EjuOTZQZAHmKNsvUtqwBfBlZxDuzZVHRBKPTDhStzZUPeEsv8lAK7AvmLv37uQjpSYMsPUF7fDS609NyruDynTpdcUtKfwkA+y5AYBdwKDHiggv3YS0KkJgoXHwqBTg9Qne8XxzoJpJ4XAd85S+7OVsH/JPCl+uAv1f4ah3waYVv1QEfVfiBweWBc5XBuk14aJvswkY7Lz+BvwVYenurmde0coqZ1HH1BmOHeituVacpQYHS9HuBAupwoF2+oH0O1M8zD+CQQn97cWkQhf6ysbcW8DVvsKogMYOqn5aqGslTj9FFZLFLx9/GzGOgTA4mb6If5ppWnpqvyrBOtAaTPegLcmOJQeaR/kRNJl0Mg+fQguua/jfUm5Qf1BwmnyP5iFjI757hoEwmmcHkBNox19r/7yz772ufhHFA91DVx3SSnyJzl0h+hC5qkZzqX3CMmJNxbBizclhZQ6F++PfxdgPTG7ThBDduCu0hi206Y+E4VCeYeoncjbfG+HpQRjHUQPPIja8MekIz2o2pz65L+h9QWPsHQwNoFbnxJqi7KWZwJ/932g9jMSt/3j0IzqgZwpz8fyYbSkzftvkz0/4BsT1vCRelHcEAAAAASUVORK5CYII=');
            background-repeat: no-repeat;
            background-size: 20px 15px;
            background-position: center;
            opacity: 1;
        }
    }

    .slick-prev {
        left: -32.5px;
    }

    .slick-next {
        right: -32.5px;
    }

    // Dots

    .slick-dots {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            width: 10px;
            height: 15px;
            margin: 0 4.5px;

            button {
                width: 100%;
                height: 100%;
                min-width: 8px;

                &::before {
                    opacity: 0.5;
                    background: $charcoal;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 250ms ease;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        height: 14px;
                        opacity: 1;
                        @include border-radius(4px);
                    }
                }
            }
        }
    }

    // light theme

    &--light {
        .slick-dots {
            li {
                button {
                    &::before {
                        background: $white;
                    }
                }
            }
        }
    }
}
