/**
 * Arlo SFCC Static
 * Main stylesheet.
 */

/* import theme */

@import 'theme/include';

/* import vendors */

@import 'vendor/include';

/* import partials */

@import 'partials/header';
@import 'partials/footer';
@import 'partials/minicart';
@import 'partials/search-bar';
@import 'partials/product-line-item';
@import 'partials/breadcrumb';
@import 'partials/region-selector';

/* --- GRID ----------------------------------------------- */

.container {
    margin: 0 auto;
    max-width: 1294px;

    &--outer {
        padding: 0 30px;
        max-width: 1324px;
    }
}

.modal-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $charcoal;
    z-index: 999;
    transition: all 0.5s ease-in-out;

    &.active {
        visibility: visible;
        opacity: 0.5;
    }
}

body {
    color: $fontColor-base;
    font-family: $fontFamily-base;
    font-size: $fontSize-default;
    font-weight: 400;
    background: $pageBg;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0;

    &.no-scroll {
        overflow: hidden;
    }
}

/* --- TYPOGRAPHY ----------------------------------------- */

// --- Headings -------------------------------------------

h1,
.h1 { @include heading-1; }

h2,
.h2 { @include heading-2; }

h3,
.h3 { @include heading-3; }

h4,
.h4 { @include heading-4; }

h5,
.h5 { @include heading-5; }

h6,
.h6 { @include heading-6; }

// --- Body Copy ------------------------------------------

p { @include body-copy; }

.large-text {
    @include body-copy-large;
}

.small-text {
    @include body-copy-small;
}

.legal-text {
    @include body-copy-legal;
}

.legal-bold-text {
    @include body-copy-legal-bold;
}

// --- Links ----------------------------------------------

a {
    @include inline-link;
}

// --- Text Links cta/nav links ----------------------------------------------

.txt-link {
    position: relative;
    display: inline-block;
    text-decoration: none;
    line-height: 1.2;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -2px;
        display: block;
        border-bottom: 1px solid currentColor;
        margin-top: 2px;
        transform: scaleX(1);
        transform-origin: 0 50%;
        transition: transform 250ms ease-in-out;
    }

    @include on-hover {
        color: inherit;
        text-decoration: none;

        &::after {
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
            transform-origin: 100% 50%;
        }
    }

    &.txt-link--inverse {
        &::after {
            content: '';
            display: block;
            transform: scaleX(0);
            transform-origin: 100% 50%;
        }

        @include on-hover {
            &::after {
                transform: scaleX(1);
                transition: transform 250ms ease-in-out;
                transform-origin: 0 50%;
            }
        }
    }
}

/* --- BUTTONS -------------------------------------------- */

// --- Default --------------------------------------------

// button,
.btn {
    @include btn;
}

// --- Images ----------------------------------------------
img {
    max-width: 100%;
    height: auto;
}
