/**
 * Partial: Product line item / info
 */

.product-line-item,
.product-info {
    display: flex;

    &:not(:last-child) {
        padding-bottom: 19px;
        margin-bottom: 19px;
        border-bottom: solid 1px $lightGray;
    }

    button {
        width: inherit;
        min-width: inherit;
        height: inherit;
        background-color: inherit;
        padding: 0;
        cursor: pointer;
        border: none;
        border-radius: 0;

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            background-color: inherit;
            outline: none;
        }
    }

    .item-image {
        width: 120px;
    }

    .product-image {
        height: 110px;
        width: 110px;
        margin: 0;
        border-radius: 8px;
    }

    .item-details {
        width: calc(100% - 220px);
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
        }

        .line-item-name {
            width: 100%;
            text-overflow: initial;
            white-space: initial;
            font-family: inherit;
            margin-bottom: 4px;
        }
    }

    .item-attributes {
        color: $darkBlueGray;
        padding-right: 10px;

        .flagged {
            color: $alertRed;
        }

        &-notices {
            margin-top: 5px;
        }
    }

    .item-soldout {
        margin-top: auto;
        color: $alertRed;
    }

    .item-quantity {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        color: $blueCharcoal;
    }

    .item-pricing {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        width: 100px;
    }

    .price {
        color: $blueCharcoal;

        .strike-through {
            color: $blueGray;
        }

        .sales {
            margin-right: 0;
        }
    }

    .line-item-quantity {
        margin-top: 5px;
        position: relative;
        display: flex;
        align-items: center;
        border: solid 1px $gray;
        border-radius: 8px;
        height: 35px;
        width: 75px;

        /* stylelint-disable */
        button {
            width: 15px;
            height: 100%;
            color: $blueCharcoal;
            font-weight: 400;

            &:first-child {
                margin-left: 10px;
            }

            &:last-child {
                margin-right: 10px;
            }
        }
        /* stylelint-enable */

        input[type=number] {
            border: none;
            height: 100%;
            text-align: center;
            width: calc(100% - 50px);
            color: $charcoal;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                appearance: none;
                margin: 0;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .remove-line-item {
        position: absolute;
        bottom: 4px;
        right: 100%;

        /* stylelint-disable-next-line */
        button {
            color: $black;
        }
    }

    .line-item-total-price {
        display: flex;
        align-items: center;
        margin-top: auto;

        .line-item-price-info {
            margin: 0;
        }

        &-label {
            font-size: inherit;
            color: $charcoal;
            font-weight: 600;
            text-transform: uppercase;
            margin-right: 10px;
        }
    }

    .cart-page & {
        .item-image {
            @include at-laptop {
                width: 165px;
            }
        }

        .product-image {
            @include at-laptop {
                width: 145px;
                height: 145px;
            }
        }

        .item-details {
            @include at-laptop {
                width: calc(100% - 265px);
            }
        }
    }
}
