$ns: '.minicart';

#{$ns} {
    position: fixed;
    top: -3px;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.04) -4px 0 16px 0;
    z-index: 999;
    transform: translateX(103%);
    background: white;
    transition: transform 500ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s;
    padding: 30px 30px 35px;

    @include at-tablet {
        width: 600px;
        padding: 30px 83px 35px;
    }

    &.active {
        transform: translateX(0);
        transition: transform 800ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s;
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 34px;

        @include at-laptop {
            margin-bottom: 93px;
        }

        h4 {
            margin-right: 11px;
            font-size: 22px;

            @include at-laptop {
                margin-right: 16px;
                font-size: 24px;
            }
        }
    }

    &__count {
        color: $blueCharcoal;
    }

    &__items {
        height: calc(100% - 300px);
        overflow: scroll;

        @include at-laptop {
            height: calc(100% - 360px);
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &-totals {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 9px;
        }

        &-disclaimer {
            margin-bottom: 21px;
        }

        &-buttons {
            > * {
                width: 100%;

                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
