/* ------ base form styling ------ */

/* ------ text input fields ------ */
input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
    @include customInput;
}

.form-control {
    font-family: $fontFamily-base;
    @include border-radius;
}

.form-float-container {
    position: relative;
    color: $blueCharcoal;

    &.active {
        label {
            transform: translate(0, 5px) scale(0.75);
        }
    }

    .form-control-label {
        font-size: 16px;
        font-family: $fontFamily-base;
        color: $blueCharcoal;
        position: absolute;
        left: 20px;
        transform-origin: top left;
        transform: translate(0, 14px) scale(1);
        transition: all 150ms ease-in-out;
        pointer-events: none;
    }

    .form-control {
        padding: 25px 20px 5px;
    }
}

/* ------ checkbox/radio base styling ------ */

.custom-control {
    position: relative;
    display: flex;
    align-items: center;
    min-height: auto;
    padding-left: 40px;
}

.custom-control-label::after,
.custom-control-label::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -36px;
    display: block;
    width: 18px;
    height: 18px;
    color: $blueCharcoal;
}

.custom-control-label::after {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
}

/* ------ checkbox/radio unchedked state ------ */

.custom-radio .custom-control-input ~ .custom-control-label::after {
    background-image: none;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $blueCharcoal;
    color: $blueCharcoal;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::after {
    background: none;
    border: solid 1px $gray;
    border-radius: 3px;
    line-height: 1.2;
    padding-left: 5px;
}

/* ------ checkbox/radio checked state ------ */

.custom-radio .custom-control-input ~ .custom-control-label {
    font-family: $fontFamily-base;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
    background-color: $blueCharcoal;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    content: 'x';
    background: none;
}

/* ------ custom select ------ */

.custom-select {
    font-family: $fontFamily-base;
}

/* ------ Select2 custom dropdowns ------ */

.select2-container--default .select2-results__option[aria-disabled=true] {
    display: none;
}

.select2-container {
    &--open {
        .select2-selection {
            border-bottom-color: $white !important;
        }

        .select2-selection__arrow {
            transform: translateY(-50%) rotate(180deg) !important;
        }
    }

    .select2-selection {
        height: 55px;
        padding: 15px 45px 15px 20px;
        border-color: solid 1px $gray;
        border-radius: 8px;

        &:focus {
            outline: none;
        }

        &__rendered {
            padding: 0 !important;

            &:focus {
                outline: none;
            }
        }

        &__arrow {
            height: 25px !important;
            top: 50% !important;
            right: 23px !important;
            transform: translateY(-50%);
            background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iIzRBNEE0QSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC42MDUgNkw0IDYuNTUgMTAgMTJsNi01LjQ1LS42MDUtLjU1LTUuMzk4IDQuOTA1eiIvPjwvc3ZnPg==) no-repeat center center;

            b {
                display: none;
            }
        }
    }
}

.select2-dropdown {
    top: -1px;
    border: solid 1px $gray;
    border-radius: 8px;
}

.select2-results__option {
    padding: 5px 20px;

    &--selected,
    &--highlighted {
        background-color: transparent !important;
        color: inherit !important;
    }
}

.select2-selection.bluegray {
    background-color: transparent;
    border-color: $white;

    .select2-selection__rendered {
        color: $white;
    }

    .select2-selection__arrow {
        background: url(../assets/svg/dropdown-arrow-white.svg) no-repeat center center;
    }
}

.select2-dropdown.bluegray {
    background-color: $blueGray;
    border-color: $white;
    color: $white;
}

.select2-selection.lighttint,
.select2-dropdown.lighttint {
    background-color: $lightTint;
}
