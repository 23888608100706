/* ============================================================================
 * Breakpoints
*/

$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;
$widescreen: 1400px;

/* ============================================================================
 * media feature expressions
*/

$tablet-up: 'min-width: #{$tablet}';
$laptop-up: 'min-width: #{$laptop}';
$desktop-up: 'min-width: #{$desktop}';
$widescreen-up: 'min-width: #{$widescreen}';

$tablet-down: 'max-width: #{$tablet - 1px}';

/* ============================================================================
 * Breakpoint Mixins
*/

/*
 * mixin to query tablet width and up
 *
 * @requires $tablet-up
*/

@mixin at-tablet {
    @media only screen and ($tablet-up) {
        @content;
    }
}

/*
 * mixin to query laptop width and up
 *
 * @requires $laptop-up
*/

@mixin at-laptop {
    @media only screen and ($laptop-up) {
        @content;
    }
}

/*
 * mixin to query desktop width and up
 *
 * @requires $desktop-up
*/

@mixin at-desktop {
    @media only screen and ($desktop-up) {
        @content;
    }
}

/*
 * mixin to query widescreen width and up
 *
 * @requires $widescreen-up
*/

@mixin at-widescreen {
    @media only screen and ($widescreen-up) {
        @content;
    }
}

@mixin tablet-down {
    @media only screen and ($tablet-down) {
        @content;
    }
}

@mixin tablet-up {
    @media only screen and ($tablet-up) {
        @content;
    }
}
