/* ------ Animations ------ */

/*
 * Pulsing animation for PDP alarm rings
 *
*/

@keyframes pulse {
    0% {
        transform: scale(0, 0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}
