$fontFamily-base: suisseintl, helvetica, arial, sans-serif;
$fontFamily-headings: $fontFamily-base;
$fontSize-default: 16px;

/**
    font-face
    Include required file types for custom webfont rendering.
 */

@mixin font-face ($family, $src, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src:
            url('#{$src}.ttf') format('truetype'),
            url('#{$src}.otf') format('opentype');
        font-style: $style;
        font-weight: $weight;
    }
}

/**
    Include your own fonts here!
 */

@at-root {
    @include font-face('suisseintl', '../assets/fonts/SuisseIntl/SuisseIntl-Regular', 400);
    @include font-face('suisseintl', '../assets/fonts/SuisseIntl/SuisseIntl-Medium', 500);
    @include font-face('suisseintl', '../assets/fonts/SuisseIntl/SuisseIntl-SemiBold', 600);
}
