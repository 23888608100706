$ns: '.header';

$headerHeightDesktop: 80px;
$headerHeightMobile: 70px;

#{$ns} {
    // position: fixed;
    height: $headerHeightMobile;
    padding: 0;
    z-index: 99;

    @include at-desktop {
        height: $headerHeightDesktop;
    }

    a {
        color: $blueCharcoal;
        font-weight: 500;
    }

    &__banner {
        display: none;
        min-height: 48px;
        background-color: $green;
        color: $white;
        align-items: center;
        text-align: center;

        &.active {
            display: flex;
        }

        a {
            color: $white;
        }

        &-content {
            padding: 12px 0;
        }
    }

    &__container {
        position: relative;
        display: flex;
        height: $headerHeightMobile;
        width: 100%;
        background-color: $white;

        @include navbar-drop-shadow;

        @include at-desktop {
            height: $headerHeightDesktop;
        }

        &.fixed {
            position: fixed;
            top: 0;
            z-index: 999;
        }
    }

    &__items {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        > * {
            position: relative;
        }
    }

    &__mobile-hamburger {
        display: block;
        cursor: pointer;
        line-height: 0;

        @include at-desktop {
            display: none;
        }

        &.active {
            .hamburger-inner {
                transition-delay: 0.22s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, -10px, 0) rotate(-45deg);

                &::before {
                    top: 0;
                    transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
                    transform: rotate(-90deg);
                }

                &::after {
                    top: 0;
                    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
                    opacity: 0;
                }
            }
        }

        .hamburger-box {
            perspective: 80px;
            position: relative;
            display: inline-block;
            width: 26px;
            height: 22px;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            position: absolute;
            width: 26px;
            height: 2px;
            transition-property: transform;
            border-radius: 4px;
            background-color: $charcoal;
        }

        .hamburger-inner {
            top: auto;
            bottom: 0;
            transition-delay: 0.13s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            transition-duration: 0.13s;

            &::before,
            &::after {
                content: '';
                display: block;
            }

            &::before {
                top: -8px;
                transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &::after {
                bottom: -8px;
                top: -16px;
                transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear;
            }
        }
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

        @include at-desktop {
            position: static;
            transform: translateY(-5px);

            img {
                width: 94px;
                height: auto;
            }
        }
    }

    &__nav {
        display: none;
        position: absolute;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 5;

        @include at-desktop {
            display: flex;
        }
    }

    &__nav-list {
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
    }

    &__nav-item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 37px;
        }
    }

    &__utilities {
        display: flex;
        align-items: center;
        z-index: 10;
        margin-left: auto;

        @include at-widescreen {
            transform: translateX(35px);
        }
    }

    &__utilities-list {
        margin: 0;
        padding: 0;
    }

    &__utilities-item {
        position: relative;
        display: inline-block;

        &.item-support {
            display: none;
            margin-right: 37px;

            @include at-desktop {
                display: inline-block;
            }
        }

        &.item-sign-in {
            display: none;
            margin-right: 37px;

            @include at-desktop {
                display: inline-block;
            }

            &:hover,
            &:focus {
                .popover {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &[data-logged-in=true] {
                .popover {
                    right: -10px;
                }
            }
        }

        &.item-search {
            opacity: 0;
            visibility: hidden;
            transition: all 0.25s ease-in-out;
            position: absolute;
            right: 0;

            @include at-desktop {
                position: static;
                visibility: visible;
                opacity: 1;
                margin-right: 29px;
            }

            .menu-open & {
                visibility: visible;
                opacity: 1;
            }
        }

        &.item-cart {
            transition: all 0.25s ease-in-out;

            .menu-open & {
                opacity: 0;
                visibility: hidden;
            }

            &.indicator::after {
                content: '';
                position: absolute;
                top: 0;
                right: -8px;
                height: 8px;
                width: 8px;
                animation: cart-icon-pulse 4s infinite;
                background-color: $green;
                border-radius: 50%;
            }

            a {
                color: inherit;
            }
        }
    }

    .cart-count {
        position: absolute;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
        left: 50%;
        top: 62%;
        transform: translate(-50%, -50%);
    }

    &__mobile-nav {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        width: 100%;
        height: 0;
        background-color: $white;
        transition: height 0.5s ease-in-out;
        overflow: hidden;
        z-index: 9999;

        .menu-open & {
            height: 100vh;
        }

        &-container {
            padding-top: 30px;
        }
    }

    &__mobile-nav-list,
    &__mobile-utilities-list {
        margin: 0;
        padding: 0;

        &-item {
            list-style-type: none;
        }
    }

    &__mobile-nav-list {
        margin-bottom: 5px;

        &-item {
            margin-bottom: 15px;
        }
    }

    &__mobile-utilities-list {
        &-item {
            margin-bottom: 10px;
        }
    }

    .popover {
        position: absolute;
        z-index: 10;
        display: block;
        top: calc(100% + 10px);
        left: auto;
        right: -30px;
        padding: 0;
        width: 250px;
        font-family: $fontFamily-base;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s;
        box-shadow: 0 0 15px 0 rgba(140, 155, 164, 0.2);
        border: 0;

        @include border-radius;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -10px;
            right: 0;
            z-index: -1;
            -webkit-filter: drop-shadow(0 0 5px rgba(140, 155, 164, 0.2));
            filter: drop-shadow(0 0 5px rgba(140, 155, 164, 0.2));
            width: 0;
            height: 0;
            border-width: 0 55px 31px;
            border-style: solid;
            border-color: transparent transparent white;
            border-image: initial;
        }

        &__content {
            padding: 25px 20px;
            background-color: $white;

            @include border-radius;

            &-headline {
                font-size: 16px;
                line-height: 24px;
                padding-bottom: 15px;
                margin-bottom: 18px;
                border-bottom: solid 1px $lightGray;

                span {
                    display: block;
                    font-size: 24px;
                    line-height: 26px;
                    color: $green;
                    font-weight: 600;
                }
            }
        }

        .nav {
            flex-direction: column;

            li {
                margin-bottom: 5px;
            }

            a {
                text-decoration: none;
                font-size: 16px;
                line-height: 14px;
            }
        }
    }
}

@keyframes cart-icon-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
    }

    50% {
        box-shadow: 0 0 0 0 $green;
    }

    to {
        box-shadow: 0 0 0 8px transparent;
    }
}
