$ns: '.breadcrumb';

#{$ns} {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 0;

    @include at-laptop {
        margin-top: 40px;
    }

    &-item {
        font-size: 14px;
        line-height: 17px;
        font-family: $fontFamily-base;

        @include at-laptop {
            font-size: 16px;
            line-height: 24px;
        }

        &:last-child {
            a {
                color: $blueCharcoal;
                text-decoration: none;

                &:hover {
                    color: inherit;
                }
            }
        }

        a {
            color: $blueCharcoal;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        + .breadcrumb-item {
            padding-left: 15px;

            &::before {
                content: '';
                width: 20px;
                height: 1px;
                background-color: $blueCharcoal;
                vertical-align: middle;
                margin-right: 15px;
            }
        }
    }
}
