$ns: '.footer';

#{$ns} {
    background-color: $blueCharcoal;
    color: $white;
    padding: 50px 0 63px;

    @include at-tablet {
        padding: 50px 0;
    }

    @include at-laptop {
        padding: 80px 0 58px;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: $white;
    }

    &__newsletter {
        margin-bottom: 53px;

        @include at-tablet {
            margin-bottom: 35px;
        }

        @include at-laptop {
            margin-bottom: 97px;
        }

        &-form {
            position: relative;
        }

        &-heading {
            margin-bottom: 26px;

            @include at-tablet {
                display: none;
            }
        }

        form {
            input[type=email] {
                background-color: $blueCharcoal !important;
                height: 55px;
                width: 100%;
                border: solid 1px $blueGray;
                border-radius: 40px;
                outline-style: inherit;
                padding: 0 25px;
                color: $white;
                font-size: 16px;
                font-weight: 600;

                @include at-tablet {
                    font-size: 22px;
                    height: 80px;
                    padding: 0 43px;
                }

                @include placeholder {
                    color: $white;

                    @include at-tablet {
                        display: none;
                        opacity: 0;
                    }
                }

                /* stylelint-disable-next-line */
                &:focus {
                    ~ span {
                        transition: opacity 0.25s ease-in-out;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }

            button[type=submit] {
                @include button-reset;

                color: $white;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
                width: initial;
                min-width: inherit;
                font-size: 16px;
                font-weight: 600;

                @include at-tablet {
                    font-size: 22px;
                    height: 80px;
                }

                @include at-laptop {
                    right: 45px;
                }

                &[disabled] {
                    color: $blueGray;
                }

                /* stylelint-disable-next-line */
                &::focus {
                    outline: none;
                }
            }

            span {
                display: none;

                @include at-tablet {
                    @include heading-4;

                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 43px;
                    transform: translateY(-50%);
                    pointer-events: none;
                    visibility: visible;
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                }
            }
        }
    }

    &__nav {
        &-col {
            &:first-child {
                margin-bottom: 55px;

                @include at-tablet {
                    margin-bottom: 0;
                }
            }

            &:last-child {
                margin-top: 55px;

                @include at-tablet {
                    margin-top: 0;
                }
            }

            &-header {
                color: $blueGray;
                margin-bottom: 20px;

                @include at-tablet {
                    margin-bottom: 34px;
                }
            }

            &-list-item {
                margin-bottom: 12px;
            }

            &-categories {
                #{$ns}__nav-col-list-item {
                    margin-bottom: 10px;

                    @include at-tablet {
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }

    &__utilities {
        align-items: center;
        margin-top: 57px;

        @include at-tablet {
            margin-top: 50px;
        }

        @include at-laptop {
            margin-top: 96px;
        }

        &-country {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 20px;
                height: auto;
                margin-right: 10px;
            }

            button {
                display: flex;
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
            }
        }

        &-copyright {
            margin-top: 20px;

            @include at-laptop {
                margin-top: 0;
                margin-right: 15px;
                display: inline-block;
            }
        }

        &-links {
            display: inline-block;
            margin: 0;
        }

        &-link {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 15px;
            }
        }

        &-social {
            margin: 0;
            margin-top: 36px;

            @include at-tablet {
                margin-top: 0;
            }

            > * {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 38px;
                }
            }

            a {
                font-size: 25px;
            }
        }
    }
}
