$ns: '.search-bar';

#{$ns} {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    // height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.04) -4px 0 16px 0;
    z-index: 9999;
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: white;
        transform: translateY(-103%);
        transition: transform 500ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s;
    }

    @include at-laptop {
        max-height: 760px;
    }

    &:not(.active) {
        pointer-events: none;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        transition: all 800ms cubic-bezier(0.32, 0.24, 0.15, 1) 0s;

        &::before {
            transform: translateY(0);
        }
    }

    .container {
        position: relative;
        max-height: 100vh;
        overflow: scroll;
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        z-index: 10;
    }

    &__container {
        position: relative;
        z-index: 1;
        padding: 30px 0 23px;
    }

    &__input {
        margin-top: 100px;

        @include at-tablet {
            margin-top: 0;
        }

        input[type=search] {
            appearance: none;
            border: 0;
            border-bottom: solid 1px $darkBlueGray;
            width: 100%;
            color: $charcoal;

            @include heading-4;

            &:focus {
                outline: none;
            }

            // styleint-disable-next-line
            &::-webkit-search-cancel-button {
                display: none;
            }

            @include at-laptop {
                max-width: 650px;
            }

            &::placeholder {
                opacity: 0.3;
                font-weight: 300;
            }
        }
    }

    &__recent-searches {
        padding-top: 86px;
        padding-bottom: 40px;

        a {
            text-decoration: none;
        }

        &-intro {
            margin-bottom: 30px;
        }

        &-list-item {
            margin-bottom: 20px;
        }
    }

    &__results {
        padding-top: 30px;

        &-intro {
            margin-bottom: 40px;

            @include at-laptop {
                margin-bottom: 27px;
            }

            h5 {
                margin-bottom: 5px;
                color: $charcoal;
            }
        }

        &-dym {
            span {
                font-weight: bold;
            }
        }

        &-container {
            @include at-laptop {
                display: flex;
            }
        }

        &-left {
            @include at-laptop {
                width: 66%;
                padding-right: 60px;
            }
        }

        &-right {
            @include at-laptop {
                width: 33%;
            }
        }

        &-headline {
            color: $charcoal;
            margin-bottom: 21px;

            @include at-laptop {
                margin-bottom: 30px;
            }

            span {
                font-size: 16px;
                line-height: 24px;
                color: $blueCharcoal;
                font-weight: 400;
            }
        }
    }

    &__products-results {
        @include at-tablet {
            display: flex;
            margin-left: -5px;
            margin-right: -5px;
        }
    }

    &__products-result {
        display: flex;
        margin-bottom: 20px;

        @include at-tablet {
            display: block;
            width: 33%;
            padding: 0 5px;
            margin-bottom: 0;
        }

        &:hover {
            .product-image {
                opacity: 0;
                visibility: hidden;
            }
        }

        a {
            text-decoration: none;
        }

        .item-image {
            position: relative;
            margin-right: 15px;

            @include at-tablet {
                margin-bottom: 10px;
            }

            img {
                margin: 0;
                transition: all 0.5s ease;

                @include border-radius;

                @include at-tablet {
                    width: 100%;
                    height: auto;
                }
            }

            .product-image {
                &-rollover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: -1;
                }
            }
        }

        .item-details {
            a {
                color: inherit;
            }
        }

        .line-item-name {
            width: 100%;
            text-overflow: initial;
            white-space: initial;
            font-family: inherit;
            margin-bottom: 9px;
        }

        .item-attributes {
            margin-bottom: 14px;

            @include at-laptop {
                margin-bottom: 10px;
            }
        }

        .price {
            font-family: inherit;
            color: inherit;

            h5 {
                display: inline-block;
                color: $charcoal;
            }
        }
    }

    &__categories,
    &__pages {
        &-result {
            margin-bottom: 5px;
        }

        a {
            text-decoration: none;
        }
    }

    &__categories {
        margin-bottom: 40px;

        @include at-laptop {
            margin-bottom: 60px;
        }
    }

    &__all-results {
        margin-top: 61px;
        text-align: center;
        margin-bottom: 100px;

        @include at-laptop {
            width: 66%;
            padding-right: 60px;
            margin-bottom: 0;
        }
    }
}
