/* ------ colors ------ */
$black: #000;
$white: #fff;

/* ------ Primarys ------ */
$green: #3c9;
$charcoal: #2b3234;
$blueCharcoal: #384952;
$darkBlueGray: #5a6871;
$blueGray: #8c9ba4;

/* ----- Secondary ------ */
$gray: #c5c9cc;
$lightGray: #dfe3e6;
$tintGray: #f1f3f5;
$lightTint: #f8f9fa;

/* ----- Tertiary ------- */
$alertRed: #ab1f1f;

/* ------ defaults ------ */
$fontColor-base:     $blueCharcoal;
$fontColor-links:    $blueCharcoal;
$fontColor-headings: $charcoal;
$pageBg:             $white;
