// -------------------------- Button <button> and <*.btn>

@mixin btn {
    position: relative;
    min-width: 230px;
    padding: 20px 40px;
    height: 55px;
    color: $white;
    font-family: $fontFamily-base;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    background-color: $green;
    border: 0;
    border-radius: 27.5px;
    cursor: pointer;
    transition: background-color 200ms ease;
    overflow: hidden;

    span {
        position: relative;
        display: inline-block;
        transform: translateX(0);
        transition: all 0.5s cubic-bezier(0.16, 0.01, 0.13, 0.99);

        &::before {
            content: '';
            display: block;
            position: absolute;
            background-image: url(../assets/svg/btn-arrow-white.svg);
            background-repeat: no-repeat;
            height: 15px;
            width: 26px;
            opacity: 0;
            right: calc(100% + 20px);
            transform: scale(0.8) translateX(0);
            transition: all 0.5s cubic-bezier(0.16, 0.01, 0.13, 0.99);
        }
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
        background-color: $green;
        box-shadow: none;
        color: $white;

        span {
            transform: translateX(20px);

            &::before {
                opacity: 1;
                transform: scale(1) translateX(5px);
            }
        }
    }

    &[disabled] {
        pointer-events: none;
        background-color: $lightGray !important;
        color: $blueGray !important;
    }

    &.btn-primary {
        background-color: $green;
        box-shadow: none;
        border: 0;

        &:hover {
            background-color: $green;
            border: 0;
        }
    }

    &.btn-secondary {
        background-color: $blueGray;
        box-shadow: none;
        border: 0;

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            background-color: $blueGray;
            border: 0;
            box-shadow: none;
        }
    }

    &.btn-white {
        background-color: $white;
        color: $charcoal;
        box-shadow: none;
        border: 0;

        /* stylelint-disable-next-line */
        span::before {
            background-image: url(../assets/svg/btn-arrow-dark.svg);
        }

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            background-color: $white;
            color: $charcoal;
            border: 0;
        }
    }
}

@mixin disabled-green-btn {
    &[disabled] {
        background-color: $green !important;
        color: $white !important;
        opacity: 0.6;
    }
}
